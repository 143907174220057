
import { Options, Vue } from 'vue-class-component'
import editClub from '@/components/Memberportal/EditClubInformation/index.vue'

@Options({
  components: {
    editClub
  }
})
export default class EditClubInformation extends Vue {}
